import React from 'react'
import Helmet from "react-helmet"
import { StaticQuery, graphql } from 'gatsby'
import { getSrc } from "gatsby-plugin-image"

const ImageForTwitter = (props) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                gatsbyImageData(
                  width:256
                  height:256
                  layout: FIXED
                )
              }
            }
          }
        }
      }
    `}

    render={(data) => {
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(props.filename);
      });
      if (!image) { return null; }

      const imagePath = 'https://hula-la.com' + getSrc(image.node.childImageSharp.gatsbyImageData);
      let meta = [];

      return (
        <Helmet
          image={imagePath}
          meta={[
            {
              property: `og:image`,
              content: imagePath,
            },
            {
              name: `twitter:image`,
              content: imagePath,
            },
          ].concat(meta)}
        >
        </Helmet>
      )
    }}
  />
)
export default ImageForTwitter