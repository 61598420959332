import {useStaticQuery, graphql} from "gatsby"

export const ShopNameTag = (shop_id, kakko=true) => {
  // const tempData  = useStaticQuery(graphql`
  //   query CommonQuery {
  //     allShopsYaml {
  //       edges {
  //         node {
  //           cid
  //           description
  //         }
  //       }
  //     }
  //   }
  // `)

  let tag = '';
  if(shop_id !== 'all'){
    const _shop_id = shop_id === 'other'? 'babiesrus' : shop_id;
    // const shop_name = getShop(tempData, _shop_id);
    const shop_name = getShop(_shop_id);
    if(kakko){
      tag = `[${shop_name}]`;
    }
    else{
      tag = `${shop_name}`;
    }
  }
  return tag;
}

// function getShop(tempData, cid){
//   const ret = tempData.allShopsYaml.edges.find(edge => {
//     return ( edge.node.cid === cid )
//   })
//   return ret.node.description
// }

function getShop(cid) {
  const shops = [
    { cid: 'aeon', description: 'イオン' },
    { cid: 'akachan', description: 'アカチャンホンポ' },
    { cid: 'amazon', description: 'Amazon' },
    { cid: 'babiesrus', description: 'ベビーザらス' },
    { cid: 'lohaco', description: 'LOHACO' },
    { cid: 'matsukiyo', description: 'マツモトキヨシ' },
    { cid: 'paypay', description: 'PayPayモール' },
    { cid: 'rakuten', description: '楽天' },
    { cid: 'yahoo', description: 'ヤフショ' },
    { cid: 'aupay', description: 'au PAY マーケット' },
    { cid: 'nishimatsuya', description: '西松屋' }
  ];

  const shop = shops.find(shop => shop.cid === cid);
  return shop ? shop.description : '';
}

export const ShopNameSelected = (shop_id) => {
  let shop_name = '';
  if(shop_id === 'all'){shop_name = 'ショップを選択'}
  else if(shop_id === 'amazon'){shop_name = '　 Amazon 　'}
  else if(shop_id === 'rakuten'){shop_name = '　　楽天　　'}
  else if(shop_id === 'yahoo'){shop_name = '　ヤフショ　'}
  else if(shop_id === 'lohaco'){shop_name = '　 LOHACO 　'}
  else if(shop_id === 'aupay'){shop_name = '　 au PAY 　'}
  else if(shop_id === 'other'){shop_name = 'ベビーザらス'}
  return shop_name;
}

export const ShopName = (shop_id) => {
  let shop_name = '';
  if(shop_id === 'all'){shop_name = '　全ショップ　'}
  else if(shop_id === 'amazon'){shop_name = '　 Amazon 　'}
  else if(shop_id === 'rakuten'){shop_name = '　　楽天　　'}
  else if(shop_id === 'yahoo'){shop_name = '　ヤフショ　'}
  else if(shop_id === 'lohaco'){shop_name = '　 LOHACO 　'}
  else if(shop_id === 'aupay'){shop_name = '　 au PAY 　'}
  else if(shop_id === 'other'){shop_name = 'ベビーザらス'}
  return shop_name;
}

export const CanonicalForShop = (canonical) => {
  let ret = canonical;
  ret = ret.replace('amazon','all');
  ret = ret.replace('rakuten','all');
  ret = ret.replace('yahoo','all');
  ret = ret.replace('lohaco','all');
  ret = ret.replace('aupay','all');
  ret = ret.replace('other','all');
  return ret;
}

export const CanonicalForOption = (canonical) => {
  let ret = canonical;
  ret = ret.replace('2','');
  ret = ret.replace('3','');
  ret = ret.replace('4','');
  return ret;
}

export const OldMessage = (item) => {
  if((item.txt) && (item.txt.includes("旧パッケージ")))
  {
    return "（旧）";
  }
  else if((item.txt) && (item.txt.includes("新パッケージ")))
  {
    return "（新）";
  }
  else if((item.txt) && (item.txt.includes("限定パッケージ")))
  {
    return "（限定）";
  }
  else if((item.txt) && (item.txt.includes("特別パッケージ")))
  {
    return "";
  }
  else
  {
    return "";
  }
}

export const OldMessageLong = (item) => {
  if((item.txt) && (item.txt.includes("旧パッケージ")))
  {
    return "旧パッケージ";
  }
  else if((item.txt) && (item.txt.includes("新パッケージ")))
  {
    return "新パッケージ";
  }
  else if((item.txt) && (item.txt.includes("限定パッケージ")))
  {
    return "限定パッケージ";
  }
  else if((item.txt) && (item.txt.includes("特別パッケージ")))
  {
    return "";
  }
  else
  {
    return "";
  }
}

export const ImageFilename = (brand_id, type_id, size_id, subbrand_id, item) => {
  if((item.txt) && (item.txt.includes("旧パッケージ")))
  {
    return `${brand_id}_${type_id}_${size_id}_${subbrand_id}_old.jpg`;
  }
  else if((item.txt) && (item.txt.includes("新パッケージ")))
  {
    return `${brand_id}_${type_id}_${size_id}_${subbrand_id}_new.jpg`;
  }
  else if((item.txt) && (item.txt.includes("限定パッケージ")))
  {
    return `${brand_id}_${type_id}_${size_id}_${subbrand_id}_limit.jpg`;
  }
  else if((item.txt) && (item.txt.includes("特別パッケージ")))
  {
    return `${brand_id}_${type_id}_${size_id}_${subbrand_id}_special.jpg`;
  }
  else
  {
    return `${brand_id}_${type_id}_${size_id}_${subbrand_id}.jpg`;
  }
}